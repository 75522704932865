<template>
  <v-row class="justify-center test">
    <v-col cols="12" md="6" xs="12" lg="4">
      <v-card shaped style="opacity: 0.7;">
        <v-card-title style="display:block" class="display-1 text-uppercase subtitle-1 text-center">
            Inicio de Sesión
        </v-card-title>
            <!-- <h1>Login</h1> -->
        <v-card-text>
          <form @submit.prevent="ingresoUsuario({user, pass})" autocomplete="off">
            <v-text-field
              v-model="user"
              label="Usuario"
              @keydown="tab"
              required
            ></v-text-field>

            <v-text-field
              v-model="pass"
              label="Contraseña"
              id="password"
              type="password"
            ></v-text-field>

            <div class="text-center">
              <v-btn block color="primary" dark type="submit">Ingresar</v-btn>
            </div>
          </form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  
  
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ingreso",
  data() {
    return {
      user: "",
      pass: ""
      
    };
  },
  methods: {
    ...mapActions(["ingresoUsuario"]),
    tab(e) {
      if (e.keyCode === 13) {
        document.getElementById("password").focus();
        e.preventDefault();
      }
    },
  },
  computed: {
    ...mapState(["error"]),
  }
};
</script>

<style>
    .test {
        padding-top: 10em;
    }
</style>